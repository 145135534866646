import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ants from '../assets/images/antsprotocol-contactus-web3-help-question (1).png';

function Contact() {
    const [laoding,setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setLoading(true);
        try {
            const response = await axios.post(`${global.backendUrl}/api/contact/send`, formData);
            
            // Show success toast
            toast.success('Message sent successfully!');
            
            // Reset form
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            // Show error toast
            toast.error('Failed to send message. Please try again.');
            console.error('Contact form submission error:', error);
        }
    };

    return (
        <div>
            <section className="tf-contact">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="image" style={{marginTop: '0px'}}>
                                <img src={ants} alt="ANTS" />
                            </div>
                        </div>
                        <div className="col-md-6" style={{marginTop: '30px'}}>
                            <div className="tf-heading style-3">
                                <h4 className="heading">Drop Up A Message</h4>
                                <p className="sub-heading">
                                    Feel free to contact us with any questions or inquiries.
                                </p>
                            </div>
                            <form onSubmit={handleSubmit} className="comment-form">
                                <fieldset className="name">
                                    <input 
                                        type="text" 
                                        id="name" 
                                        placeholder="Your Full Name" 
                                        className="tb-my-input" 
                                        name="name" 
                                        value={formData.name}
                                        onChange={handleChange}
                                        required 
                                    />
                                </fieldset>    
                                <fieldset className="email">
                                    <input 
                                        type="email" 
                                        id="email" 
                                        placeholder="Your Email Address" 
                                        className="tb-my-input" 
                                        name="email" 
                                        value={formData.email}
                                        onChange={handleChange}
                                        required 
                                    />
                                </fieldset>
                                <fieldset className="subject">
                                    <input 
                                        type="text" 
                                        id="subject" 
                                        placeholder="Your Subject" 
                                        className="tb-my-input" 
                                        name="subject" 
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required 
                                    />
                                </fieldset>
                                <fieldset className="message">
                                    <textarea 
                                        id="message" 
                                        name="message" 
                                        rows="4" 
                                        placeholder="Message" 
                                        value={formData.message}
                                        onChange={handleChange}
                                        required 
                                    />
                                </fieldset>
                                <div className="btn-submit">
                                    <button className="tf-button" type="submit">
                                       {laoding ? 'Sending...' : 'Send Message'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;