// import React, { useEffect, useState } from "react"
// import { getAllFaq } from "../../api/Api"

// const FAQComponent = () => {
//   const [expandedIndex, setExpandedIndex] = useState(null)

//   const toggleItem = (index) => {
//     setExpandedIndex((prevIndex) => (prevIndex === index ? null : index))
//   }
//     const [dataAPI, setDataAPI] = useState([]);
//     const [loading, setLoading] = useState(true);  

//     const getData = async () => {
//       try {
//         setLoading(true);  
//         const res = await getAllFaq();
//         console.log(res)
//         setDataAPI([...res.data.data]);
//       } catch (error) {
//         console.error("Error fetching faq data:", error);
//       } finally {
//         setLoading(false);  
//       }
//     };

//     useEffect(() => {
        
//         getData()
//       }, []);
//       console.log(dataAPI)
//   return (
//     <div className="faq-container tf-container">
//       <h4 className="heading" style={{textAlign:'center',marginTop:'40px',marginBottom:'40px'}}>FAQ - AntsProtocol</h4>
//       {dataAPI.map((item, index) => (
//         <div key={index} className="faq-item">
//           <button className="faq-question" onClick={() => toggleItem(index)} aria-expanded={expandedIndex === index}>
//             <span className="ssp">{item.title}</span>
//             <span className="faq-icon">{expandedIndex === index ? "−" : "+"}</span>
//           </button>
//           <div className={`faq-answer ${expandedIndex === index ? "expanded" : ""}`}>
//             <p>{item.description}</p>
//           </div>
//         </div>
//       ))}
//     </div>
//   )
// }

// export default FAQComponent

import React, { useState } from "react"
const faqData = [
  {
    question: "How do I get started with AntsProtocol?",
    answer:
      "To get started, connect your MetaMask wallet to the AntsProtocol platform, purchase a MintBack Ticket, and begin funding projects or participating in the DAO governance.",
  },
  {
    question: "What is AntsProtocol?",
    answer:
      "AntsProtocol is a decentralized platform built on Ethereum that combines crowdfunding and philanthropy, enabling users to fund profit-driven projects or NGOs transparently and securely through smart contracts, receive rewards, and contribute to value creation.",
  },
  {
    question: "What is a MintBack Ticket, and how does it work?",
    answer:
      "A MintBack Ticket allows users to mint ANTS tokens for every transaction made on the platform, which are instantly transferred to their wallets. Additionally, MintBack Tickets unlock access to advanced platform features.",
  },
  {
    question: "What is the MintBack system?",
    answer:
      "The MintBack system enables the minting of ANTS tokens as rewards for every transaction on the platform, including ticket purchases, donations, and investments. MintBack Tickets offer varying minting and cashback rates based on transaction volumes."
  },
  {
    question: "What types of projects can I fund on AntsProtocol?",
    answer:
      "AntsProtocol supports two types of projects:NGO Projects: Charitable initiatives focused on the environment, people, or animals. Profit-Driven Projects: Innovative projects in sectors like DeFi, GameFi, Next-Gen Tech, Ai-Agentic Ai, and more."
  },
  {
    question: "How can I add a project to AntsProtocol?",
    answer:
      " To add a project, connect your wallet to the platform and follow the project submission process. Submit all required documentation and provide details about your project milestones. Once verified through a rigorous review process, your project will be listed for funding."
  },
  {
    question: "How are funds managed for NGO projects?",
    answer:
      "For NGO projects, funds are directly transferred from the donor's wallet to the project's wallet via smart contracts, ensuring transparency and security for every transaction."
  },
  {
    question: "How are funds managed for profit-driven projects?",
    answer:
      "Funds for profit-driven projects are released upon reaching predefined milestones. If a project fails to meet its funding goal or milestones, the funds are refunded to investors."
  },
  {
    question: "What are ANTS tokens, and what are they used for?",
    answer:
      " ANTS is an ERC-20 utility token that powers the AntsProtocol ecosystem. It can be used for MintBack rewards, participation in the DAO governance, staking pools, and access to advanced platform features."
  },
  {
    question: "What is DAO governance on AntsProtocol?",
    answer:
      "The DAO governance allows users to influence strategic decisions on the platform. Each ANTS token represents one vote. Token holders can propose and vote on changes, and tokens used for voting are burned. Voters are rewarded for their participation."
  },
  {
    question: "How are projects verified on AntsProtocol?",
    answer:
      " Every project undergoes a rigorous verification process, including document checks, introductory video calls, and milestone evaluations, to ensure legitimacy and reliability."
  },
  {
    question: "What are the benefits of participating in the DAO governance?",
    answer:
      " By participating in the DAO, you can directly shape the platform's development, contribute to strategic decisions, and receive exclusive rewards like lotteries, airdrops, and staking bonuses. Additionally, the first private sale will be open exclusively to DAO members."
  },
  {
    question: "How does AntsProtocol control ANTS token inflation?",
    answer:
      " Inflation is managed through mechanisms like burning tokens used in DAO votes, locking tokens in staking pools, and a dynamic MintBack algorithm that will adjust cashback rates based on tokenomics conditions."
  },
  {
    question: "How can I earn ANTS tokens on AntsProtocol?",
    answer:
      "Initially, the only way to obtain ANTS tokens is through the MintBack system. Secure one of the available tickets early to get a boost on minting rates, fund projects, or participate in the DAO."
  },
  {
    question: "What is the long-term vision of AntsProtocol?",
    answer:
      "AntsProtocol aims to become the leading platform for decentralized crowdfunding and philanthropy, promoting sustainability, innovation, and global collaboration."
  },
]
const FAQComponent = () => {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const toggleItem = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index))
  }
  return (
    <div className="faq-container tf-container">
      <h4 className="heading" style={{textAlign:'center',marginTop:'20px'}}>FAQ - AntsProtocol</h4>
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <button className="faq-question" onClick={() => toggleItem(index)} aria-expanded={expandedIndex === index}>
            <span className="ssp">{item.question}</span>
            <span className="faq-icon">{expandedIndex === index ? "−" : "+"}</span>
          </button>
          <div className={`faq-answer ${expandedIndex === index ? "expanded" : ""}`}>
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
export default FAQComponent