import Blog01 from './Blog01';
import Blog02 from './Blog02';
import BlogDetails01 from './BlogDetails01';
import BlogDetails02 from './BlogDetails02';
// import Collection from './Collection';
import Contact from './Contact';
import Create from './Create';
import Dashboard from './Dashboard';
// import Explore01 from './Explore01';
// import Explore02 from './Explore02';
import Explore03 from './Explore03';
// import Explore04 from './Explore04';
import Faqs from './Faqs';
import HelpCenter from './HelpCenter';
import Home01 from './Home01';
// import Home02 from './Home02';
// import Home03 from './Home03';
// import Home04 from './Home04';
// import Home05 from './Home05';
// import Home06 from './Home06';
// import ItemDetails01 from './ItemDetails01';
// import ItemDetails02 from './ItemDetails02';
// import LiveAutions01 from './LiveAutions01';
// import LiveAutions02 from './LiveAutions02';
import Login from './Login';
// import Ranking from './Ranking';
import SignUp from './SignUp';
import Wallet from './Wallet';
import { useStateContext } from '../components/context/AppContext';
import ProjectDetail from './ProjectDetail';
import Otp from './Otp';
import UserProfile from './UserProfile';
import Projects from './Projects';
import Pools from './Pools';
import Ngo from './Ngo';
import Profitable from './Profitable';
import UpdateProject from './UpdateProject';
import LaunchPage from './launch/LaunchPage';
import GovernancePage from './governance/GovernancePage';
import PoolDetail from './PoolDetail';
import PrivateSaleModule from './PrivateSaleModule';
import CreateProposal from './CreateProposal';
import ProposalDetailPage from './ProposalDetailPage';
import FAQComponent from './allFaq';

const routes = [
  { path: '/', component: <Home01 /> },
  // { path: '/commerce', component: <Explore03 /> },
  // { path: '/collection', component: <Collection /> },
  // { path: '/product-detail/:slug', component: <ItemDetails01 /> },
  { path: '/project-detail/:slug', component: <ProjectDetail /> },
  { path: '/pool-detail', component: <PoolDetail /> },
  { path: '/private-sale-module', component: <PrivateSaleModule /> },
  // { path: '/item-details-v2', component: <ItemDetails02 /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/createProposal', component: <CreateProposal /> },
  { path: '/proposal-detail', component: <ProposalDetailPage /> },
  // { path: '/ranking', component: <Ranking /> },
  { path: '/howitworks', component: <HelpCenter /> },
  { path: '/faq', component: <FAQComponent /> },
  { path: '/benefits', component: <Faqs /> },
  { path: '/subscription', component: <Wallet /> },
  { path: '/login', component: <Login /> },
  { path: '/signup', component: <SignUp /> },
  { path: '/create', component: <Create /> },
  { path: '/update-project/:id', component: <UpdateProject /> },
  { path: '/blogs', component: <Blog02 /> },
  // { path: '/knowledge', component: <Blog01 /> },
  { path: '/blog-details-v1/:slug', component: <BlogDetails01 /> },
  { path: '/launch', component: <LaunchPage /> },
  // { path: '/blog-details-v2', component: <BlogDetails02 /> },
  { path: '/contact', component: <Contact /> },
  { path: '/otp', component: <Otp /> },
  { path: '/user-profile/:slug', component: <UserProfile /> },
  { path: '/pools', component: <Pools /> },
  { path: '/projects', component: <Projects /> },
  { path: '/ngo-projects', component: <Ngo /> },
  { path: '/profitable-projects', component: <Profitable /> },
  { path: '/governance', component: <GovernancePage /> },
];

export default routes;
